import './public-path'

import Vue from 'vue'
import I18n from 'vue-i18n'

import iComponents from '@app'

import { extend } from 'vee-validate'
/* eslint-disable camelcase */
import {
  required,
  min,
  confirmed,
  length,
  max,
  max_value,
  min_value,
} from 'vee-validate/dist/rules'

import is from 'is_js'
import { install } from '@/install'
import { createApp } from '@/app'

import './styles/index.sass'
import '@/js/directives'
import '@utils/events'

import { decimal, validateCryptoAddress } from '@/js/validation'

Vue.config.productionTip = false
Vue.config.performance = true

Vue.use(install)
Vue.use(iComponents)
// Vue.config.devtools = true;

const { app } = createApp({
  Vue,
  I18n,
})

app.$mount('#app')

extend('required', { ...required, message: app._('This field is required.') })
extend('length', { ...length, message: app._('This field is not valid.') })
extend('min_8', { ...min, message: () => `${app._('The number of characters must be at least')} 8` })
extend('max_value', { ...max_value, message: (e, a) => `${app._('Field value cannot be greater than')} ${a.max}` })
extend('min_value', { ...min_value, message: (e, a) => `${app._('Field value cannot be less than')} ${a.min}` })
extend('min', { ...min, message: (e, a) => `${app._('Field value length cannot be less than')} ${a.length}` })
extend('max', { ...max, message: (e, a) => `${app._('Field value length cannot be greater than')} ${a.length}` })
extend('email', {
  validate(value) {
    return /^[a-zA-Zа-яА-ЯяёЁЇїІіЄєҐґ\+0-9_.-]{2,64}@[a-zA-Zа-яА-ЯяёЁЇїІіЄєҐґ0-9_.-]+[.a-zA-Zа-яА-ЯяёЁЇїІіЄєҐґ]{0,6}$/.test(value)
  },
  message() {
    return app._('The field must be a valid email address.')
  },
})

extend('decimal', {
  validate(value, args) {
    return decimal.validate(value, args)
  },
  message: (e, a) => `${app._('FThe number of characters in the fractional part should not exceed')} ${a['0']}`,
});
extend('validateCryptoAddress', {
  validate(value, args) {
    return validateCryptoAddress(value, args)
  },
  message: () => app._('Incorrect filled field'),
});
extend('password', {
  validate(value) {
    if (7 > (value || '').length) return false;
    // const PASSWORD_REG = /^(?=.*?[A-ZА-Я])(?=.*?[a-zа-я])(?=.*?[0-9]).*$/;
    const PASSWORD_REG = /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[,.#?!@$%^&*-]).{8,}$/
    return PASSWORD_REG.test(value);
  },
  computesRequired: true,
  message: app._('Latin characters only. Minimum eight characters, at least one uppercase letter, one lowercase letter, one number and one special character'),
});
extend('confirmed', { ...confirmed, message: app._('Password mismatch') })
extend('url', {
  validate: value => is.url(value),
  message: app._('This filed must be a valid URL'),
})
extend('ip', {
  validate: value => is.ip(value),
  message: app._('This filed must be a valid ip address'),
})

const MOBILEREG = /^[+() -\d]+$/

extend('phone', {
  validate(value) {
    // Regex for a international phone number
    return MOBILEREG.test(value)
  },
  message: app._('Phone is not valid'),
})
