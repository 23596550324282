import VueI18n from 'vue-i18n'
import VueRouter from 'vue-router'
import Vuex from 'vuex'
import VTooltip from 'v-tooltip'
import VModal from 'vue-js-modal'
import Tag from '@aspectus/vue-tag'
import VueBem from '@aspectus/vue-bem'
import VueGrid from '@aspectus/vue-grid'
import DatePicker from 'vue2-datepicker'
import VueConditionalAttrs from 'vue-conditional-attrs'
import 'vue-animate-transitions/dist/vue-animate-transitions.min.css'
import VueAccordion from '@ztrehagem/vue-accordion'
import VueSlickCarousel from 'vue-slick-carousel'
import VueClipboard from 'vue-clipboard2'
import 'vue-slick-carousel/dist/vue-slick-carousel.css'
import VueApexCharts from 'vue-apexcharts'
import Notifications from 'vue-notification'
import ReadMore from 'vue-read-more'
import { ObserveVisibility } from 'vue-observe-visibility';
import VueTable from '@aspectus/vue-table';

import 'vue2-datepicker/index.css'

/* eslint-disable no-unused-expressions */
import(`vue2-datepicker/locale/${window.language}`)

export function install(Vue) {
  Vue.directive('observe-visibility', ObserveVisibility);
  Vue.use(VueI18n)
  Vue.use(VueRouter)
  Vue.use(Vuex)

  Vue.use(VTooltip)
  Vue.use(Tag)
  Vue.use(VueGrid)
  Vue.use(VueApexCharts)
  Vue.use(DatePicker)
  Vue.use(Notifications)
  Vue.use(VueClipboard)
  Vue.use(ReadMore)
  Vue.use(VueTable);
  Vue.use(VModal, {
    dynamic: true,
    dialog: true,
    height: 'auto',
    dynamicDefaults: {
      transition: 'nice-modal-fade',
      scrollable: true,
    },
  })
  Vue.use(VueConditionalAttrs)
  Vue.use(VueBem, {
    blockNameKey: 'block',
  })
  Vue.component('vue-accordion', VueAccordion)
  Vue.component('apex-chart', VueApexCharts)
  Vue.component('vue-slick-carousel', VueSlickCarousel)
}

export default { install }
