import {
  ValidationProvider,
  ValidationObserver,
} from 'vee-validate'

import Vue from 'vue'

import './rules'
import decimal from './decimal';
import { validateCryptoAddress } from './crypto-address'

export {
  decimal,
  validateCryptoAddress,
}

Vue.component('ValidationProvider', ValidationProvider)
Vue.component('ValidationObserver', ValidationObserver)
