const validateCryptoAddress = (value, options) => {
  const [type] = options
  console.log(value, type, options);

  const SimpleRegex = '^.{6,}$'
  const FirstRegex = '^(0x)[0-9A-Fa-f]{40}$'
  const SecondRegex = '^T[1-9A-HJ-NP-Za-km-z]{33}$'
  const ThirdRegex = '^[1-9A-HJ-NP-Za-km-z]{32,44}$'
  const FourRegex = '^(0x)[0-9A-Fa-f]{40,64}$'
  const FifthRegex = '^(bnb1)[0-9a-z]{38}$'

  const regexs = {
    BTC: '^[13][a-km-zA-HJ-NP-Z1-9]{25,34}$|^(bc1)[0-9A-Za-z]{39,59}$',
    LTC: '^(L|M|3)[A-Za-z0-9]{33}$|^(ltc1)[0-9A-Za-z]{39}$',
    BCH: '^[13][a-km-zA-HJ-NP-Z1-9]{25,34}$|^(bc1)[0-9A-Za-z]{39,59}$|^((bitcoincash:)?(q|p)[a-z0-9]{41}$)',
    ADA: '^(([0-9A-Za-z]{57,59})|([0-9A-Za-z]{100,104}))$',
    ETHBEP20: FirstRegex,
    ETH: FirstRegex,
    DOGE: '^(D|A|9)[a-km-zA-HJ-NP-Z1-9]{33,34}$',
    NEO: '^(A)[A-Za-z0-9]{33}$',
    XRP: '^r[1-9A-HJ-NP-Za-km-z]{25,34}$',
    USDTTRC: SecondRegex,
    USDTSOL: ThirdRegex,
    USDTAVAXC: FirstRegex,
    USDTC: FirstRegex,
    USDCAVAXC: FirstRegex,
    USDCC: FirstRegex,
    USDCXLM: '^[G][a-zA-Z0-9]{40,60}$',
    TRX: SecondRegex,
    USDTERC: FirstRegex,
    USDTPOLYGON: FirstRegex,
    USDTMATIC: FirstRegex,
    USDCPOLYGON: FirstRegex,
    USDCMATIC: FirstRegex,
    USDTARBTM: FirstRegex,
    WBTTRC: SecondRegex,
    WBTERC: FirstRegex,
    WBTWHITE: FirstRegex,
    BNB: FifthRegex,
    BNBBEP20: FifthRegex,
    NOT: SimpleRegex,
    DOGS: SimpleRegex,
    USDTTON: SimpleRegex,
    TON: SimpleRegex,
    POL: FirstRegex,
    MATICPOLYGON: FirstRegex,
    UAHGTRC20: SecondRegex,
    UAHGERC20: FirstRegex,
    UAHGBEP20: FirstRegex,
    AXS: FirstRegex,
    ARWV: SimpleRegex,
    FLOKI: FirstRegex,
    PEPE: FirstRegex,
    RNDR: FirstRegex,
    IMTBL: FirstRegex,
    GRAPH: FirstRegex,
    FIL: SimpleRegex,
    FET: FirstRegex,
    DGWIF: ThirdRegex,
    GALA: FirstRegex,
    CAKE: FirstRegex,
    OP: FirstRegex,
    STRKERC20: FirstRegex,
    STRK: FourRegex,
    SUI: '^(0x)[0-9A-Fa-f]{64}$',
    RON: FourRegex,
    METIS: FirstRegex,
  }
  if (regexs[type]) {
    const regex = new RegExp(regexs[type])
    return regex.test(value)
  }
  return true
}

export {
  validateCryptoAddress,
}
